<template>
  <div>
    <DropdownMenu v-if="auth.isAuthenticated">
      <DropdownMenuTrigger as-child>
        <slot />
      </DropdownMenuTrigger>
      <DropdownMenuContent class="mt-1 w-56" align="center">
        <!-- <DropdownMenuLabel class="flex font-normal">
          <div class="flex flex-col space-y-1">
            <p class="text-sm font-medium leading-none">{{ auth.username }}</p>
            <p class="text-xs leading-none text-muted-foreground">
              {{ email }}
            </p>
          </div>
        </DropdownMenuLabel>

        <DropdownMenuSeparator /> -->

        <DropdownMenuGroup>
          <DropdownMenuItem @click="openDashboardPage">
            Open Dashboard
            <DropdownMenuShortcut>
              <Icon
                size="1.125rem"
                name="lucide:external-link"
                class="ml-3 self-center opacity-70"
              />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
          <DropdownMenuItem @click="openTipPage">
            My Tip Page
            <DropdownMenuShortcut>
              <Icon
                size="1.125rem"
                name="lucide:external-link"
                class="ml-3 self-center opacity-70"
              />
            </DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuItem>
          <NuxtLink
            to="/settings"
            class="flex w-full items-center"
            @click="
              () =>
                $track('user_menu_item_clicked', {
                  menu_item_name: 'plugin_settings',
                })
            "
          >
            Plugin Settings
            <!-- <DropdownMenuShortcut>⇧⌘L</DropdownMenuShortcut> -->
          </NuxtLink>
        </DropdownMenuItem>

        <DropdownMenuSeparator />

        <DropdownMenuItem @click="restart">
          Restart
          <!-- <DropdownMenuShortcut>⌘R</DropdownMenuShortcut> -->
        </DropdownMenuItem>

        <DropdownMenuItem @click="logout">
          Log out
          <!-- <DropdownMenuShortcut>⇧⌘L</DropdownMenuShortcut> -->
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>

    <Button
      v-else
      variant="outline"
      class="w-full items-center justify-start gap-2"
      @click="auth.tryLogin"
    >
      <Avatar size="sm" class="bg-secondary">
        <AvatarFallback>
          <Icon size="1rem" name="lucide:user" />
        </AvatarFallback>
      </Avatar>
      <span class="text-sm font-semibold">Login</span>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { openBrowserTo } from "~/lib/bindings";

// const username = ref("fishsticks");
const email = ref("XXXX@logitech.com");
const auth = useAuthStore();

const fallackLetters = computed(() => auth.username?.[0]);

const openTipPage = () => {
  $track("user_menu_item_clicked", { menu_item_name: "open_tip_page" });
  if (auth.tipPageUrl) {
    openBrowserTo(auth.tipPageUrl);
  }
};

const openDashboardPage = () => {
  $track("user_menu_item_clicked", { menu_item_name: "open_dashboard" });
  openBrowserTo("https://streamlabs.com/dashboard");
};

const logout = () => {
  $track("user_menu_item_clicked", {
    menu_item_name: "logout",
  });

  auth.logout();
};

const restart = () => {
  $track("user_menu_item_clicked", {
    menu_item_name: "restart",
  });

  window.location.href = "/";
};

const { track: $track } = useTrackingStore();
</script>
